import React, {useState} from 'react' 
import { Tab , Nav} from 'react-bootstrap';
import imageLogo from "../../webroot/images/login-logo-2.svg";
import logo from "../../webroot/images/logo.svg";
import EmailUser from "./EmailUser";
import MobileUser from './MobileUser'
import { useTranslation } from "react-i18next"; 
import welcomeLogo from "../../webroot/images/welcomeLogo.svg" ;


function Login() {
    const { t } = useTranslation('common');

    const [showCaptcha, setShowCaptcha] = useState(0);

    const activeTabHandler = () => {
        setShowCaptcha((prev)=>prev+1);
    }
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="login-outer">
                    <div className="login-left-panel">
                        <div className="login-bg">
                             <div className="logo-img">
                                {<img src={logo} className="" alt='logo' />}
                            </div> 
                            <div className="logo-img">
                                {<img src={welcomeLogo} className="" alt='logo' />}
                            </div> 
                            <div className="login-txt">
                                <span></span>
                            </div> 
                            {<img src={imageLogo} className="login-bg-img" alt='logo-bg' />}
                        </div>
                    </div>
                    <div className="login-right-panel">
                        <div className="login-heading">
                            <h2>{t('FORM.LOGIN')}</h2>

                        </div>
                        <Tab.Container id="" defaultActiveKey="first">
                            <div className="login-tab"> 

                                <Nav variant="pills" className="flex-column" onSelect={() => activeTabHandler()}> 
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">By Email</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">By Mobile</Nav.Link>
                                    </Nav.Item>
                                </Nav>


                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <EmailUser/> 
                                        
                                    </Tab.Pane>  
                                    <Tab.Pane eventKey="second">
                                        <MobileUser showCaptcha={showCaptcha}/> 
                                    </Tab.Pane>    
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login