import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux'; 
import { useNavigate } from "react-router-dom"; 
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { VALIDATE_OTP } from "../../services/login.gql";
import { useApolloClient } from "@apollo/client";
import { loggedIn } from '../../store/reducers/userReducer';
import { toast } from 'react-toastify';

const VerifyOtp = (props) => { 
    const maxTime = 59;
    const client = useApolloClient();
    const [errField, setErrField] = useState({});
    const [inputField, setInputField] = useState({
        ...props.inputField, otp: ""
    });
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useNavigate();
    const navigate = useNavigate();

 
    const { t } = useTranslation('common');
    useEffect(()=>{
        setInputField({ ...inputField, user_id : props.userId })
        // eslint-disable-next-line 
    },[props.userId]) 


    const [otpTimer, setOtpTimer] = useState(maxTime);

    const inputHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value });
    };
 

    useEffect(() => {
        let startInterval;
        if (otpTimer >= 0) {

            startInterval = setInterval(() => {
                setOtpTimer((val) => val - 1)

            }, 1000)
        } else {
            clearInterval(startInterval)
            setOtpTimer(-1)
        }

        return () => {
            clearInterval(startInterval)

        }
    }, [otpTimer])

    const submitButton = async () => { 
        setLoading(true); 
        if (validForm()) { 
            executeGraphQLQuery(VALIDATE_OTP(inputField.email, inputField.otp, ''), client)
            .then(async (res) => {
                setLoading(false);
              if (res?.data?.validate_otp?.message === 'success') {
                let LoginData = props.LoginData
                let user_data = {
                  user_id: LoginData.id,
                  name: LoginData.name,
                  email: LoginData.email,
                  mobile: LoginData.mobile
                };
                LoginData = { ...LoginData, access_token: res.data.validate_otp.access_token, user_data: user_data };
                localStorage.setItem('loggedIn', '1');
                localStorage.setItem('user_information', JSON.stringify(LoginData)); 
                toast.success('OTP Validation Successful');
                navigate('/dashboard'); 
             //   setIsLoggedIn(true);
                dispatch(loggedIn()); 
             } else {   
                 toast.error(res?.data?.validate_otp?.message || ''); 
             }
            })  
        } else {
            props.setReloadCaptcha((prev)=> prev+1)
            setLoading(false);
        }
    };

    //For validation

    const validForm = () => {
        let formIsValid = true;
        setErrField({
            captchErr: ""
        });
        if (inputField.otp === "" || inputField.otp.trim() === '') {
            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                otp: t('VALIDATION.OTP'),
            }));
        } 
        return formIsValid;
    };

    /**
     * Resend OTP Handler
     */
    const resendOtp = () => {
        if (otpTimer <= 0) {
         //   dispatch(UserActions.createOtp({ ...inputField, resendOtp: true })).then(res => setOtpTimer(maxTime))
        }
    }
    
    return (
        
        <div>
            <fieldset className="">
                <div className={"material " + (errField.otp ? "error" : "")}    >
                    <input
                        type="text"
                        placeholder=" "
                        name="otp"
                        value={inputField.otp}
                        className={"form-input "+ (resendOtp?'resendOtpActive':'')}
                        onChange={inputHandler}
                        maxLength={6}
                    />
                    <i className="ic-otp icn-login">
                        <i className='path1'></i>
                        <i className='path2'></i>
                        <i className='path3'></i>
                        <i className='path4'></i>
                    </i>

                    <label data-label={t('FORM.OTP')} className="form-label"></label>
                    {errField.otp && (
                        <span className={"erroe-txt " + (resendOtp?'resendOtpActiveError':'')}>
                            {errField.otp}
                        </span>
                    )}
                </div>
                {
                    <p onClick={resendOtp} >{otpTimer < 0 ? 'Resend Otp' : 'resend otp after '+(otpTimer > 9 ? "0:" + otpTimer : "0:0" + otpTimer)+" seconds" }</p>
                }


            </fieldset>
 

            <button type="button" className="btn-primary" onClick={submitButton} disabled={loading}>
                {t('FORM.LOGIN')}
            </button>

        </div>
    )
}

export default VerifyOtp