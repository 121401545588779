export const TITLE = 'AGENTBOX :: CARMUDI';
export const LOGIN_PAGE_LOGO = require('../../webroot/images/logo.svg');

export const CURRENCY_SYMBOL = '₱';
export const CURRENCY_FORMAT = '.';

export const language = [
    { value: '1', label: 'English',iso_code:'en', key:'ENG' }
];

export const CORE_URL = 'http://localhost:8561/'
