import React, { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ResponsiveContainer, Cell } from 'recharts';
import { Tab, Nav } from "react-bootstrap";   


const IncentiveChart = (props) => {
  const {monthList, quarterList} = props;
  const [activeTab, setActiveTab] = useState(1); 
  const [focusBar, setFocusBar] = useState();


  const CustomTooltip = ({ active, payload }) => { 
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
          <p>{`Total GMV: ₱ ${payload?.[0]?.payload?.total_gmv}`}</p>
          <p>{`Disbursed: ${payload?.[0]?.payload.total_disbursal}`}</p>
        </div>
      );
    }

    return null;
  };
  

  let data = +activeTab === 1 ? monthList : quarterList;
  return (
    <div className='incentive-chart chart-table-card'>
     <h2>Incentive Payment</h2>
      <Tab.Container id="left-tabs-example" defaultActiveKey={'1'} activeTab={activeTab} >
        <Nav variant="pills" className="flex-column tab-bg bg-white p-lg-b">
          <div className="tab-list">
            <Nav.Item onClick={() => setActiveTab('1')}>
              <Nav.Link eventKey="1">Monthly</Nav.Link>
            </Nav.Item>
            <Nav.Item onClick={() => setActiveTab('2')}>
              <Nav.Link eventKey="2">Quarterly </Nav.Link>
            </Nav.Item>

          </div>
        </Nav>

        <Tab.Content key={activeTab} id={activeTab}>
          <Tab.Pane eventKey={activeTab}>
            <div className=''> 
                {/* <BarChart width={500} height={350} data={+activeTab === 1 ? monthList : quarterList}>
                  <CartesianGrid stroke="#f5f5f5" />
                  <XAxis dataKey="x_axis_name" />
                  <YAxis axisLine={false} tick={false}/>
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey="total_disbursal" fill="#00BFFF" radius={[100, 100, 0, 0]}/>
 
                </BarChart>    */}
 
 
                <BarChart
                  width={500}
                  height={350}
                  data={+activeTab === 1 ? monthList : quarterList}
                  margin={{
                    top: 50,
                    right: 50,
                    left: 0,
                    bottom: 5,
                  }}
                  barSize={50}
                  onMouseMove={state => {
                    if (state.isTooltipActive) {
                      setFocusBar(state.activeTooltipIndex);
                    } else {
                      setFocusBar(null);
                    }
                  }}
                >
                  <XAxis dataKey="x_axis_name" scale="point" padding={{ left: 0, right: 10 }}  
                    allowDataOverflow={false}
                    strokeWidth={0}
                  />
                  <YAxis axisLine={false} tick={false}/>
                  <CartesianGrid stroke="#f5f5f5" /> 
                  <Tooltip  content={<CustomTooltip />}/> 
                <Bar dataKey="total_disbursal" radius={[100, 100, 0, 0]}>
                  {data.map((entry, index) => (
                    <Cell cursor="pointer" key={Cell - index} fill={focusBar === index ? '#00BFFF' : '#E1E6F1'} />
                  ))}
                </Bar>

                </BarChart> 

            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

    </div>
  );
};

export default IncentiveChart;
