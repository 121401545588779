import secureStorage from './encrypt';

//GET COUNTRY CODE FROM ENV   
let countryCode = process.env.REACT_APP_COUNTRY_CODE || "id";
let appLanguage = process.env.REACT_APP_DEFAULT_LANG || "id";

//IMPORT COUNTRY CONSTANTS
var path = './'+countryCode+'_config/constant';
var req = require.context("./", true);
var constants = req(path);


//SET COUNTRY CODE IN STORAGE
secureStorage.setItem('countryCode', countryCode);

let configPath = countryCode+'_config/';
secureStorage.setItem('CONFIG_PATH',configPath);

const CONFIG={};
CONFIG.constants = constants;

//SET GATEWAY URL
let REACT_APP_GETWAY_API = process.env.REACT_APP_GETWAY_API;

CONFIG.API_URL = {
    GETWAY_API: REACT_APP_GETWAY_API,
    LANG: appLanguage,
    CORE_URL: constants.CORE_URL
}

export default CONFIG;
