import moment from "moment";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { useApolloClient } from '@apollo/client';
import InputField from "../common/InputField";
import MultiSelect from '../common/MultiSelect'
import React, { useEffect, useState } from "react";
import ReactSelect from "../common/ReactSelect";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { getMasterData } from "../../store/action/allAction";
import {useSearchParams } from 'react-router-dom';




const LeadListFilter = (props) => {
    let { fetchListingData, setParentFilter,activeTab,parentfilter }=props;
    const client = useApolloClient();
    const [filters, setFilters] = useState({})
    const [statusList, setStatusList] = useState([])
    const [schemeType, setSchemeType] = useState([])
    const [modifyStatusList, setModifyStatusList] = useState([])
    const [searchParams, setSearchParams] = useSearchParams();
    const [showRange, setShowRange] = useState(false);
    const [amountRange, setAmountRange] = useState('');
    let search = searchParams.get('search');
    let status = searchParams.get('status');
    
   

    const dispatch = useDispatch()
    const masterState = useSelector((state) => state.masterdata)

    const get_master_data = () => {
        dispatch(getMasterData(client));
    }

    const handleChange = (key, value) => {
        let updatedFilters = filters

        if (['scheme_type_id', 'status'].includes(key)) {
            let selectedOptionsArray = value.map((v) => v.value)
                updatedFilters = { ...updatedFilters, [key]: selectedOptionsArray }
        }

        if (['search', 'amount_from', 'amount_to'].includes(key)) {
            let val=(['amount_from', 'amount_to'].includes(key))? +value:value;
            updatedFilters = { ...updatedFilters, [key]: val }
        }

        if (['modify_status'].includes(key)) {
            updatedFilters = { ...updatedFilters, [key]: value.value }
        }

        if (key === 'incentive_month') {
            let date = moment(value).format('MMMM YYYY')
            updatedFilters = { ...updatedFilters, [key]: date }
        }
        setFilters({ ...updatedFilters })
    }

    const getLastMonth = (returnType) => {
        if (returnType === 'month') {
            return moment().subtract(1, 'months').format('MMM YYYY');
        } else if (returnType === 'date') {
            return moment().subtract(1, 'months').endOf('month').format('YYYY-MM-01');
        }
    }
    const getMonthFormat = (value,returnType) => {
        if (returnType === 'month') {
            return moment(new Date(value)).format('MMM YYYY');
        }else if (returnType === 'date') {
            return moment(new Date(value)).format('YYYY-MM-01');
        }
    }

    const handleSearch = (type) => {
        let updatedFilters = { ...filters }
        let fieldsToString = ['scheme_type_id', 'status']

        fieldsToString.forEach((ele) => {
            if (updatedFilters?.[ele]) {
                updatedFilters[ele] = updatedFilters[ele].toString()
            }
        })

        //before sending month change format to YYYY-MM-01
        if (updatedFilters?.['incentive_month']) {
            updatedFilters['incentive_month'] = moment(updatedFilters['incentive_month']).format('YYYY-MM-01');
        }

        //when reseting search, apply default filters
        if (type === 'reset') {
            setAmountRange('');
            let resetFilters = { page_number: 1, view_type:activeTab }
            if(activeTab===1){
                resetFilters.incentive_month=getLastMonth('date'); 
            }else{
                delete resetFilters.incentive_month;
            }
            updatedFilters = resetFilters
            setFilters(resetFilters)
        }

        fetchListingData({ ...updatedFilters }, type)
    }
    const ShowHideAmountRange=(type)=>{
        if(type){
            setShowRange(false); 
        }else{
            setShowRange(true); 
        }
    }

    const submitAmount=()=>{
        let amountRange='';
        if(filters.hasOwnProperty('amount_from') && filters.hasOwnProperty('amount_to') &&  filters['amount_from']!='' && filters['amount_to']!=''){
            amountRange=`${filters['amount_from']}- ${filters['amount_to']}`;
        }else if(filters.hasOwnProperty('amount_from') && filters['amount_from']!=""){
            amountRange=`${filters['amount_from']}`;
        }else if(filters.hasOwnProperty('amount_to') &&  filters['amount_to']!=""){
            amountRange=`${filters['amount_to']}`;
        }
        setAmountRange(amountRange);
        setShowRange(false);
    }

    useEffect(() => {
        if (!Object.keys(masterState?.data)?.length) {
            get_master_data()
        } else {
            let { scheme_type_list, status_list, modify_status_list } = masterState?.data
            setStatusList(status_list?.map((ele) => ({ label: ele.name, value: ele.id })))
            setSchemeType(scheme_type_list?.map((ele) => ({ label: ele.name, value: ele.id })))
            setModifyStatusList(modify_status_list?.map((ele) => ({ label: ele.name, value: ele.id })))
        }
    }, [masterState])

    useEffect(() => {
        //let updatedSelctedFilters = {};
        let updatedSelctedFilters = {"page_number":1,"view_type":activeTab,};
        if(activeTab===1){
            updatedSelctedFilters.incentive_month= getLastMonth('date')
        }
        if (search) {
            updatedSelctedFilters['search'] = search;
        }
        if (status) {
            updatedSelctedFilters['status'] = status;
        }
        setParentFilter({...updatedSelctedFilters})
        setFilters({ ...updatedSelctedFilters })
    }, [search,status])
    
    
    return (
        <React.Fragment>
            <div className="lead-list-filter">
                <fieldset className="search-bx">
                    <div className="material" title='Search By Dealer Name, GCD'>
                        <input id="" type="text" placeholder=" " name="RoleName" className="form-input" onChange={(e) => handleChange('search', e.target.value)} value={filters?.['search'] || ''} />
                        <i className="ic-search"></i>
                        <label data-label={"Search By Dealer name, GCD"} className="form-label"></label>
                    </div>
                </fieldset>

                
                
                <fieldset className="showRange">
                    <div className="material" title='Search By Dealer Name, GCD'>
                        <input 
                        id="incentive_range" 
                        type="text" placeholder=" " 
                        name="incentive_range" 
                        className="form-input"  
                        value={amountRange || ''} 
                        onClick={(e) =>ShowHideAmountRange(showRange)}
                        />
                        <label data-label={"Select Incentive Range"} className="form-label"></label>
                    </div> 
                    
                   { showRange &&  <div  className="min-max-dropdown">
                    <fieldset className="single-select">
                    <div className="material">
                        <InputField
                            inputProps={{
                                id: "amount_from",
                                type: "text",
                                name: "amount_from",
                                placeholder: " ",
                                value: filters?.['amount_from'] || '',
                                className: "form-input",
                                disabled: false,
                                maxLength: 10
                            }}
                            onChange={(e) => { handleChange('amount_from', e.target.value) }}
                        />
                        <label data-label={"Select From"} className="form-label"></label>

                    </div> 
                </fieldset>
                

                <fieldset className="single-select">
                    <div className="material">
                        <InputField
                            inputProps={{
                                id: "amount_to",
                                type: "text",
                                name: "amount_to",
                                placeholder: " ",
                                value: filters?.['amount_to'] || '',
                                className: "form-input",
                                disabled: false,
                                maxLength: 10
                            }}
                            onChange={(e) => { handleChange('amount_to', e.target.value) }}
                        />
                        <label data-label={"Select To"} className="form-label"></label>

                    </div>
                </fieldset>

                <div className="btn-submit-reset">
                    <button className="btn-primary" onClick={() => submitAmount()} >Submit</button> 
                </div>
                    </div>}
                </fieldset>

               

                <fieldset className="single-select">
                    <div className="material">
                        <MultiSelect
                            isMulti={true}
                            options={schemeType}
                            placeholder="Select Type"
                            iconAfter="false"
                            value={schemeType.filter((e) => filters?.['scheme_type_id']?.includes(e.value))}
                            className="react-select"
                            classNamePrefix="react-select"
                            onChange={(e) => handleChange('scheme_type_id', e)}
                        />

                    </div>
                </fieldset>

                <ReactSelect
                    options={modifyStatusList}
                    value={modifyStatusList.find((e) => e.value === filters?.['modify_status']) || null}
                    placeholder={"Modify Status"}
                    isDisabled={false}
                    fieldKey="modify_status"
                    handleChange={(e, fieldKey) => handleChange(fieldKey, e)}
                    fieldSetClassName="react-select"
                />

                <fieldset className="single-select">
                    <div className="material">
                        <MultiSelect
                            isMulti={true}
                            options={statusList}
                            placeholder="Select Status"
                            iconAfter="false"
                            value={statusList.filter((e) => filters?.['status']?.includes(e.value))}
                            className="react-select"
                            classNamePrefix="react-select"
                            onChange={(e) => handleChange('status', e)}
                        />

                    </div>
                </fieldset>

                {
                  (activeTab===1) && 
                    <fieldset className="dateragefiled">
                        <div className="material">
                            <div className="datepicker">
                                <DatePicker
                                    selected={(filters?.['incentive_month'])?new Date(filters?.['incentive_month']):getLastMonth('date')}
                                    dateFormat="MM/yyyy"
                                    placeholderText="Month"
                                    showMonthYearPicker
                                    maxDate={getLastMonth("date")}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    onChange={(e) => handleChange('incentive_month', e)}
                                    value={getMonthFormat(filters?.['incentive_month'],'month') || getLastMonth("month")}

                                />
                            </div>
                        </div>
                    </fieldset>
                }
                {
                  (activeTab===2) && 
                    <fieldset className="dateragefiled">
                        <div className="material">
                            <div className="datepicker">
                                <DatePicker
                              
                                    selected={(filters?.['incentive_month'])?new Date(filters?.['incentive_month']):null}
                                    dateFormat="MM/yyyy"
                                    placeholderText="Month"
                                    showMonthYearPicker
                                    maxDate={getLastMonth("date")}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                    onChange={(e) => handleChange('incentive_month', e)}
                                    value={(filters?.['incentive_month'])?getMonthFormat(filters?.['incentive_month'],"month"):''|| ''}
                                />
                            </div>
                        </div>
                    </fieldset>
                }

                <div className="btn-submit-reset">
                    <button className="btn-primary" onClick={() => handleSearch('search')} >Search</button>
                    <button className="btn-reset" onClick={() => handleSearch('reset')}>Reset</button>
                </div>
            </div>
        </React.Fragment>
    );
};
export default LeadListFilter;
