import { gql } from '@apollo/client';

export const GET_INCENTIVE_DATA = () => gql`
query{
    scheme_list(status:1) {
      monthly_list{
          id
          disbursal_from
          disbursal_to  
          gmv_from
          gmv_to
          incentive_amount
          row
          scheme_type
          status
      }
      quarterly_list{
          id
          disbursal_from
          disbursal_to  
          gmv_from
          gmv_to
          incentive_amount
          row
          scheme_type
          status
      }
      annual_list{
          id
          disbursal_from
          disbursal_to  
          gmv_from
          gmv_to
          incentive_amount
          row
          scheme_type
          status
      }
  }
}
`;

export const SAVE_INCENTIVE_DATA = gql`mutation save_scheme($schemeInput: [SchemeInput!]!, $scheme_type: Float!,$created_by: Float!) {
        save_scheme(scheme_input: $schemeInput, scheme_type: $scheme_type, created_by: $created_by) {
            data{
                id 
                disbursal_from  
                disbursal_to
                gmv_from
                gmv_to
            }
            message
            is_error
        }
    }`

export const GET_INCENTIVE_HISTORY =()=> gql`query{scheme_history}`;


export const GET_DASHBOARD_LIST = () => {
    return gql
    `query{
      dashboard_list {
        dashboardCards{
            name
            status
            total_count 
        }  
        topDisbursal{
            id
            total_gmv 
            total_disbursal
            amount
            dealer_name
            gcd_code
        }  
    }
    }`
}


export const GET_CSV_UPLOAD = () => {
    return gql
    ` mutation ($file: Upload!, $user_id: Float!, $email: String!, $bulk_upload_type : Float!) {
        csv_upload(csvInput: {images: [$file], user_id: $user_id, email: $email, bulk_upload_type : $bulk_upload_type}) {
        generated_url
        message
        errorMsg
        is_error
        }
    }`
}


export const GET_CHART_LIST = () => {
    return gql
    `query{
      chart_list { 
        monthList{
            x_axis_name
            total_disbursal
            total_gmv 
        } 
        quarterList{
            x_axis_name
            total_disbursal
            total_gmv 
        }   
    }
    }`
}
      