
const masterDataReducer = (state = '', action) => {
    switch (action.type) {
        
        case "GET_MASTER_DATA": 
			return {
				...state,
				data: action.payload,
			}; 
		default:
            return state
    }
}

export default masterDataReducer;
