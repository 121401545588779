import React from "react";
import Select, { components } from 'react-select';
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};


const ReactSelect = (props) => {

    return (
        <>
            <fieldset className={`single-select`} key={props.key || "reactSelect"}>
                <div className="material">
                    <Select
                        components={{ ValueContainer: CustomValueContainer }}
                        options={props.options || []}
                        value={props.value}
                        onChange={(e) => props.handleChange(e, props.fieldKey)}
                        placeholder={props.placeholder}
                        className='react-select'
                        classNamePrefix='react-select'
                        isDisabled={props.isDisabled}
                    />
                    
                    {props?.errorField ? <span className="error-txt">{props.errorField}</span> : null}

                </div>
            </fieldset>
        </>
    );
};

export default ReactSelect;