import secureStorage from "./encrypt";
import _ from "lodash";
export const API_URL = {
  GETWAY_API: process.env.REACT_APP_GETWAY_API, 
  API_KEY: "agentbox-ac1faa7b-9fe9-4483-9525-5cc4ce94c639"
};
 
export const IS_USER_ACCESS_PAGE = (controller, action) => {
	let isUserHasRights = false, isEditAccess = false;
	const userAccessFeatures = secureStorage.getItem("userAccess");
	if (userAccessFeatures && userAccessFeatures.length) {
		let isKeyExist = _.findKey(userAccessFeatures, function (v) {
			if (controller && v.controller && action && v.action) {
				return (
					v.controller.toLowerCase() === controller.toLowerCase() && 
					v.action.toLowerCase() === action.toLowerCase()
				);
			}
		});
		isUserHasRights = isKeyExist ? true : false;
		isEditAccess = isKeyExist ? ((Number(userAccessFeatures[isKeyExist].access_type) === 1) ? true : false) : false
	}

	return {
		is_access_page: isUserHasRights,
		is_edit_access: isEditAccess
	};
};

export const APIV = 2;
 
export const REDIRECT_URLS = {
	"beta" : "http://sso-beta.carmudi.com.ph/google?redirect=",
	"production" : "https://sso.carmudi.com.ph/google?redirect=",
	"pre-production" : "https://pre-sso.carmudi.com.ph/google?redirect=",
	"local" : "http://sso-beta.carmudi.com.ph/google?redirect="
}

export const REDIRECT_URL = REDIRECT_URLS[process.env.REACT_APP_ENV]