import React, { useEffect,useState } from 'react'; 
import Modal from "../common/Modal";
import { useApolloClient } from '@apollo/client'; 
import {GET_DEALER_HISTORY} from "../../services/dealerdata.gql";  
import { executeGraphQLQuery} from "../../../src/common/executeGraphQLQuery"; 
import FullPageLoader from '../common/FullPageLoader';
import * as HELPER from "../../config/helper";
import { toast } from 'react-toastify';
import CurrencyInputField from "../common/CurrencyInputField"; 

const HistoryModal = (props) => {
    const client = useApolloClient();
    const [dealerHistory, setDealerHistory] = useState([]);
    const [loading, setLoading] = useState(false);
 

    useEffect(() => { 
        getDealerHistoryList();
    },[]);

    const getDealerHistoryList = async() => {
        setLoading(true);
        executeGraphQLQuery(GET_DEALER_HISTORY(props?.data?.id), client).then(response => { 
            setLoading(false);
            if(response && response?.data && response?.data?.user_history && response?.data?.user_history.data && !response?.data?.user_history.is_error) {
                let result = response.data.user_history.data;  
                setDealerHistory(result);
               
            }else{
                setDealerHistory([]);
                toast.error(response?.data?.user_history.message);
            }
        }).catch((error) => {
        
        });
    };

     
    return ( 
            <div className="dealer-incentive-history-popup model-popup-outer">
                 {/* <FullPageLoader show={loading} /> */}
                <Modal show={props.viewTimeLine} handleClose={() => props.showModalViewTimeLine(false)} >
                <div className="modal-header">
                                <h2>Incentive History for {props?.data?.name} </h2>
                            </div>

                    <div className="modal-body" >
                        <div className="data-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Incentive Month</th>
                                        <th>Disbursal and <br></br> Total GMV</th>
                                        <th>Incentive</th>
                                        <th>Bank Details</th>
                                        <th>Salary <br></br>Arrear</th>
                                        <th>Deduction</th>
                                        <th>Final <br></br>Incentive</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                    dealerHistory.map((inc, index) => {
                                            let bankDetails=``;
                                            if(inc.bank_name){
                                                bankDetails+=inc.bank_name;
                                            }
                                            if(inc.account_number){
                                                bankDetails+=" "+inc.account_number;
                                            }
                                            if(inc.beneficial_name){
                                                bankDetails+=" "+inc.beneficial_name;
                                            }
                                        return <tr key={index}>
                                            <td>{inc.scheme_type_name}</td>
                                            <td>{inc.incentive_month}</td>
                                            <td>{inc.total_disbursal} Disbursal GMV   {HELPER.addCurrencyFormatting(inc.total_gmv)}</td>
                                            <td>{HELPER.addCurrencyFormatting(inc.amount)}</td>
                                            <td className='truncate'  title={`${bankDetails}`}>{inc.bank_name}.{inc.account_number} {inc.beneficial_name}</td>
                                            <td>
                                                <CurrencyInputField
                                                    inputProps={{
                                                        id: `salary_arrear_${inc.id}`,
                                                        type: "text",
                                                        name: "salary_arrear",
                                                        placeholder: "Salary",
                                                        value: `${inc.salary_arrear}`,
                                                        disabled:(inc.status===1 && [4,2].includes(inc.modify_status))?false:true,
                                                        className: `txt-value-input`,
                                                        title: "Salary Arrear"
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <CurrencyInputField
                                                    inputProps={{
                                                        id: `deduction_${inc.id}`,
                                                        type: "text",
                                                        name: "deduction_",
                                                        placeholder: "Deduction",
                                                        value:`${inc.deduction}`,
                                                        disabled:(inc.status===1 && [4,2].includes(inc.modify_status))?false:true,
                                                        className: `txt-value-input`,
                                                        title: "Deduction"
                                                    }}
                                                />
                                            </td>
                                            <td>{HELPER.addCurrencyFormatting(inc.final_amount)}</td>
                                            <td>{inc.status === 3 ? <span className="paid-status">{inc.status_name}</span> : inc.status_name}</td>
                                            
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div> 

                    </div>
                </Modal>
            </div> 
    )
};

export default HistoryModal;
