import { executeGraphQLQuery } from "../../common/executeGraphQLQuery"; 
import { GET_MASTER_DATA } from "../../services/masterdata.gql";  
import { GET_DEALER_INCENTIVE_DATA } from "../../services/dealerdata.gql";  
 
export const getMasterData = (client) => {
	return async (dispatch) => {
		try {
			executeGraphQLQuery(GET_MASTER_DATA(), client).then(response => { 
				if(response && response?.data && response.data?.master_config) {
					let result = response.data.master_config;  
					dispatch(success(result))
				} else dispatch(success({}));
			})
			.catch((error) => {
				dispatch(success({}));
             });
		} catch (err) {
			dispatch(success({}))
		}
	};
	function success(payload) { 
		return { type: "GET_MASTER_DATA", payload };
	}
}

export const getDealerData = (client,params) => {
	return async (dispatch) => {
		return new Promise((resolve, reject) => {
			try {
				executeGraphQLQuery(GET_DEALER_INCENTIVE_DATA(),client,params).then(response => { 
					if(response && response.data && response.data.incentive_list) {
						let result = response.data.incentive_list;  
						dispatch(success(result));
						resolve(response);
					} else dispatch(success({}));
				}).catch((error) => {
					dispatch(success({}));
				 });
			} catch (err) {
				dispatch(success({}))
			}
		});
	};
	function success(payload) { 
		return { type: "GET_DEALER_DATA", payload };
	}
}

 