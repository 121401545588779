import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import { UserActions } from "../../store/action";
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { useApolloClient } from "@apollo/client";
import { GET_CAPTCHA } from "../../services/login.gql";
import { loginCaptcha } from '../../store/reducers/userReducer';

const Captcha = (props) => {

  const client = useApolloClient();
  const [captcha, setCaptcha] = useState('')
  const [captchaInvalid, setCaptchaInvalid] = useState('')
  const [captchaImg, setCaptchaImg] = useState({})

  const dispatch = useDispatch();

  useEffect(() => {

    loadCaptchaImage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reloadCaptcha])


  useEffect(() => {
    setCaptchaInvalid(props.errField.captcha)
  }, [props.errField])


  /**
   *  Load Captcha Image
   */

  const loadCaptchaImage = (err = []) => {
    setCaptcha("");

    executeGraphQLQuery(GET_CAPTCHA(), client)
      .then(async (res) => { 
        if (res?.data?.get_captcha) {
          setCaptchaImg(res.data.get_captcha)
          dispatch(loginCaptcha(res.data.get_captcha));
          // let LoginData = props.LoginData
          // let user_data = {
          //   user_id: LoginData.id,
          //   name: LoginData.name,
          //   email: LoginData.email,
          //   mobile: LoginData.mobile
          // };
          // LoginData = { ...LoginData, access_token: res.data.validate_otp.access_token, user_data: user_data };
          // localStorage.setItem('loggedIn', '1');
          // localStorage.setItem('user_information', JSON.stringify(LoginData));
          // toast.success('OTP Validation Successful');
          // navigate('/dashboard');
          // //   setIsLoggedIn(true);
          // dispatch(loggedIn());
        }  
      })

    // dispatch(UserActions.loginCaptcha()).then(res=>{
    //     if(res && res.data && res.data.data){
    //         setCaptchaImg(res.data.data)
    //     }
    // })

  };

  /**
   * Input Handler
   * @param e (synthatic element)
   */
  const handleInputChange = async e => {
    let captchaVal = e.target.value;

    setCaptcha(captchaVal)

    props.onKeyPressCaptcha(captchaVal);
  };

  /**
   * Reset Captcha Error
   */
  // const resetCaptchaError = async () => {
  //     if(this._isMount){
  //     await this.setState({
  //         captchaInvalid: ""
  //     });
  //     }

  // };


  const { captchaError } = props;

  return (
    <>
      <div className={captchaInvalid ? "material error" : "material"}>

        <input
          id="captcha"
          type="text"
          placeholder=" "
          name="captcha"
          value={captcha}
          className="form-input"
          onChange={handleInputChange}
        />

        <i className="ic-survey icn-login"></i>

        <label data-label="Captcha" className="form-label"></label>
        <span className="erroe-txt">{captchaInvalid || ''}</span>

        <span className="password-show" style={{ left: "282px", "fontSize": '20px' }}>
          {captchaError ? (
            ''
          ) : (
            <>
              <img src={`data:image/svg+xml;base64,${window.btoa(
                captchaImg.image
              )}`}
                className="captchaimg" alt="" style={{ width: '80%' }} />
              <span
                className="reload"
                onClick={loadCaptchaImage}
                title="reload captcha"
              >
                &#x21bb;
              </span>
            </>
          )}
        </span>

      </div>
    </>
  );
}

export default Captcha;
