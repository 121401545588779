import { useState } from 'react';
import Captcha from "../common/Captcha"; 
// import { createOtp } from "@src/store/slices/user"; 
import { useSelector, useDispatch } from "react-redux";
import VerifyOtp from "./VerifyOtp";
import { Login_User } from "../../services/login.gql";
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { useApolloClient } from "@apollo/client"; 
import { loggedIn } from '../../store/reducers/userReducer';
import { toast } from 'react-toastify';

function MobileUser(props) { 
    const captchaData = useSelector((state) => { 
        return state.user;
    })
    const client = useApolloClient(); 
    const dispatch = useDispatch();
    const [otp, setOtp] = useState("");
    const [LoginData, setLoginData] = useState(null); 
    const [loading, setLoading] = useState(false);
    const [inputField, setInputField] = useState({
        email: "",
        password: "",
        captcha: "",
        hash: "",
        mobile :""
    });
    const [errField, setErrField] = useState({
        email: "",
        password: "",
        isCaptchaInvalid: false,
        captchaError: "",
        captcha: "",
        otp: "",
        mobile:""
    });
    const [showVerify, setShowVerify] = useState(false);
    const [userId, setUserId] = useState('');
    const [reloadCaptcha, setReloadCaptcha] = useState(0);

    const validForm = () => {
        let formIsValid = true;
        setErrField({
            email: "",
            password: "",
            isCaptchaInvalid: false,
            captchaError: "",
            captcha: "",
            otp: "",
            mobile :""
        });

        if (inputField.mobile === "") {

            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                mobile: "Mobile is required",
            }));
        } 
        if (!inputField['captcha'].trim()) {

            formIsValid = false;
            setErrField((prevState) => ({
                ...prevState,
                captcha: "Captcha is required",
            }));
            setReloadCaptcha((prev) => prev + 1)
        }

        return formIsValid;
    };

    const inputHandler = (e) => {
        const { name, value } = e.target;
        if (name === "otp") {
            setOtp(value);
        }
        
        else if (name === "mobile") {
            // Validate mobile number to accept only numbers starting from 9 and have a length of 10 digits
            if (/^[9]\d{0,9}$/.test(value) || value === "") {
                setInputField({ ...inputField, [name]: value });
                setErrField((prevState) => ({ ...prevState, mobile: "" }));
            } else {
                if(value.length <=10){

                    setErrField((prevState) => ({
                        ...prevState,
                        mobile: "Mobile number must start with 9",
                    }));
                }
            }
        } 

        else {
            setInputField({ ...inputField, [name]: value });
        }

        errField[name] = '';
        setErrField({ ...errField });
    };


    const submitButton = async () => {

        setLoading(true);
        if (validForm()) { 

            executeGraphQLMutation(Login_User, inputField, client)
                .then((result) => {
                    setLoading(false);
                const loginData = result?.data?.login_user; 
                if (loginData && !loginData?.is_error) {  
                    //DELETE CAPTCHA HASH FROM CACHE  
                    dispatch(loggedIn(loginData));
                    setLoginData(loginData);
                    setUserId(loginData.id);
                    setShowVerify(true); 
                    setReloadCaptcha((prev)=> prev+1)
                    setInputField({ email: loginData?.email, password: inputField.password, captcha: ''}) 
                } 
                else{
                    setLoading(false);
                    setReloadCaptcha((prev)=> prev+1)
                    toast.error(loginData.message); 
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    setReloadCaptcha((prev)=> prev+1)
                    toast.error("Invalid Username and Password"); 
                })  
            // createOtp(inputField).then((res) => {
            //     setLoading(false)
            //     if (res && res.data) {
            //         //DELETE CAPTCHA HASH FROM CACHE 
            //         setUserId(res && res.data && res.data.user_id);
            //         setShowVerify(true);
            //         props.setShowEmailLinks(false);
            //        props.setShowMobileLinks(true);
            //     }
            //     setReloadCaptcha((prev) => prev + 1)
            //     setInputField({ ...inputField, mobile: inputField.mobile, password: inputField.password, captcha: '' })

            // });
        } else {
            setReloadCaptcha((prev) => prev + 1)
            setLoading(false);
        }
    };
 
    const captchaFiledHandler = (childData) => {
        if(captchaData?.captchaValue){
           setInputField({ ...inputField, captcha: childData,hash :captchaData.captchaValue, loginWithGoogle : true})
        }
    }


    return (
        <div className="login-form">
            {showVerify ? 
                (<VerifyOtp submitButton={submitButton} userId={userId} inputField={inputField} captchaData={ captchaData }  reloadCaptcha={reloadCaptcha} setReloadCaptcha={setReloadCaptcha} setUserId={setUserId} setshowVerify={setShowVerify} LoginData={LoginData}/>)
                :
                <form autoComplete="on">
                   
                    <fieldset className="">
                        <div className={"material " + (errField.mobile ? "error" : "")}    >
                            <input
                                type="number"
                                placeholder=" "
                                name="mobile"
                                value={inputField.mobile}
                                className="form-input"
                                onChange={inputHandler}
                            />
                            <i className="ic-mobile icn-login"></i>
                            <br/>
                            <label data-label="Mobile No." className="form-label"></label>
                            {errField.mobile && (
                                <span className="error-txt">
                                    {errField.mobile || ""}
                                </span>
                            )}
                            
                        </div>
                    </fieldset> 

                    <fieldset className=" captcha-filed">
                        <Captcha onKeyPressCaptcha={captchaFiledHandler} errField={errField} inputField={inputField} reloadCaptcha={reloadCaptcha || props.showCaptcha} />

                    </fieldset>

                    <div className="language-selectors">

                        <button type="button" className="btn-primary" onClick={submitButton} disabled={loading}>
                            Login
                        </button>
                    </div>

                </form>
            }
        </div>
    );
}

export default MobileUser;