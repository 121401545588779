import {API_URL} from '../config/constant';
export function executeGraphQLQuery(query, client, variables= {}, header = '') {

  return client.query({
    query: query,
    variables
  })
    .then(res => {
      if (res?.errors?.[0]?.statusCode === 403 ||
        res?.errors?.[0]?.message.includes('Invalid Token')) {
        localStorage.clear();
        window.location.href = '/login';
      } 
      return res;
    })
    .catch(err => {
      const { networkError } = err;
      if (networkError.statusCode === 401) {
        localStorage.clear()
        window.location.href = '/login';
      }
    });
}


export function executeGraphQLMutation(mutation, variables, client) {

  return client.mutate({
    mutation: mutation,
    variables,
  })
}

export async function executeCsvUpload(formData) {

  const response = await fetch(`${API_URL.GETWAY_API}api/v1`, {
        method: 'POST',
        headers: {
          'x-apollo-operation-name': 'true',
        },
        body: formData,
      });
  const result = await response.json()
  return result;
}
