import { configureStore } from '@reduxjs/toolkit';
import userReducer from './reducers/userReducer'; 
import masterDataReducer from './reducers/masterDataReducer'
import dealerDataReducer from './reducers/dealerDataReducer'
import { loadStateFromLocalStorage, saveStateToLocalStorage } from './localStorage';

const persistedState = loadStateFromLocalStorage();

const store = configureStore({
  reducer: {
    user: userReducer, 
    masterdata: masterDataReducer,
    dealerdata:dealerDataReducer
  },
  preloadedState: persistedState,
});

store.subscribe(() => {
  saveStateToLocalStorage(store.getState());
});



export default store;