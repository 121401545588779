import React, { useState } from 'react';

const TopDealerList = (props) => { 
    const {topDisbursalList} = props;
   
 
    return (
        <div className='chart-table-card top-dealers-card'>
            <h2>Top 5 Dealers</h2>
               <div className="data-table">
        <table>
            <thead>
                <tr id={1}> 
                    <th>Dealer Detail</th>
                    <th>Total GMV</th>
                    <th>Disbursal</th>
                    <th>Incentive Amount</th> 
                </tr>
            </thead>
            <tbody>
                {topDisbursalList.length > 0 ? (
                    topDisbursalList?.map((item) => (
                        <tr className={""} key={item.id}> 
                            <td>
                                <span className="truncate">{item.dealer_name}</span>
                                <span className="truncate">(GCD {item.gcd_code})</span>
                            </td>
                            <td>
                                <span className="truncate">₱ {item.total_gmv}</span>
                            </td>
                            <td>
                                <span className="truncate">{item.total_disbursal}</span>
                            </td>
                            <td>
                                <span className="truncate">₱ {item.amount}</span>
                            </td> 
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={4} className="no-data">
                            No Data Found
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
        </div> 
    )
}

export default TopDealerList;