export const TITLE = 'AGENTBOX :: OTO';
export const LOGIN_PAGE_LOGO = require('../../webroot/images/logo.svg');

export const CURRENCY_SYMBOL = '₱';
export const CURRENCY_FORMAT = '.';

export const language = [
    { value: '1', label: 'English',iso_code:'en' , key:'ENG'},
    { value: '2', label: 'Indonesian',iso_code :'id',key:'ID'}
];

export const CORE_URL = 'http://localhost:8561/'
export const DEALER_FILE_TYPE = 3;