import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client'; 
import { Tab } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; 
import { useDispatch, useSelector } from "react-redux";
import secureStorage from "../../config/encrypt";
import { toast } from 'react-toastify';
import FullPageLoader from '../common/FullPageLoader';
import IncentiveChart from './common/IncentiveChart';
import TopDealerList from './common/TopDealerList';
import { getMasterData } from "../../store/action/allAction";
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import {GET_DASHBOARD_LIST, GET_CHART_LIST} from  "../../services/incentivedata.gql";
import moment from 'moment';
//import generatedIcon from "../../webroot/images/generatedIcon.svg";
import generatedIcon from "../../webroot/images/generated_icon.png";
//import paidIcon from "../../webroot/images/paidIcon.svg";
import paidIcon from "../../webroot/images/paid_icon.png";
import onHoldIcon from "../../webroot/images/onHoldIcon.svg";
import requestIcon from "../../webroot/images/requestIcon.svg";

export default function Dashboard(props) {
    const client = useApolloClient();
    const dispatch = useDispatch();
    let [dashboardCards, setDashboardCards] = useState(); 
    let [loading, setLoading] = useState(false);
    const [name, setName] = useState("");  
    /* eslint-enable no-unused-vars */
    let [searchValue, setSearchValue] = useState(""); 
    const history = useNavigate(); 
    const { masterdata } = useSelector(({ masterdata }) => ({ masterdata }));
    let [monthList, setMonthList] = useState([]);
    let [quarterList, setQuarterList] = useState([]);
    let [topDisbursalList, setTopDisbursalList] = useState([]);
    const USER_DATA = JSON.parse(localStorage.getItem("user_information")); 

    useEffect(() => { 
        const result = JSON.parse(localStorage.getItem('user_information'));
         
        if (result) { 
            let userInfo = result.user_data;
            setName((userInfo && userInfo.name) || ''); 
        }
    }, []);

    const setNewState = () => {
        // dispatch(userNameSet("Insurance Box"))
    }

    useEffect(() => { 
        get_master_data(); 
        getDashboardData();
        getChartData();
    }, []);

    const get_master_data = () => {
        dispatch(getMasterData(client));
    }


    const getDashboardData = () => {
        executeGraphQLQuery(GET_DASHBOARD_LIST(), client)
        .then(async (res) => {
          setLoading(false); 
          if (res?.data?.dashboard_list) {
            let result = res.data?.dashboard_list; 
            setTopDisbursalList(result?.topDisbursal??[]);  
            setDashboardCards(result?.dashboardCards); 
          } else {   
            toast.error(res?.errors?.[0]?.message || ''); 
          }
        })  
    }


    const getChartData = () => {
        executeGraphQLQuery(GET_CHART_LIST(), client)
        .then(async (res) => {
          setLoading(false); 
          if (res?.data?.chart_list) {
            let result = res.data?.chart_list;
            setMonthList(result?.monthList);
            setQuarterList(result?.quarterList);  
          } else {   
            toast.error(res?.errors?.[0]?.message || ''); 
          }
        })  
    }

    const countClickHandler = (e, val) => { 
        if(val && val>0){
            history('/dealer-list?status='+e);
        } 
    }

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    }

    const redirectSearch = () => { 
        if(searchValue){
            history('/dealer-list?search='+searchValue);
        }  
    }

    const getLastMonth = () => {
        return moment().subtract(1, 'months').endOf('month').format('MMMM YYYY');
    }
  
    let paidCount = dashboardCards?.find(item => +item.status === 3)?.total_count;
    let requestedCount = dashboardCards?.find(item => +item.status === 2)?.total_count;
    let onHoldCount = dashboardCards?.find(item => +item.status === 4)?.total_count;
    let generatedCount = dashboardCards?.find(item => +item.status === 1)?.total_count;

    return (
        <React.Fragment> 
            <div className="dashboard-bg">
                <div className="dashboard-outer">
                    <div className="heading">
                        <span className="subheading">Hello {USER_DATA?.name}</span>
                        <h1 onClick={() => setNewState()}>Welcome to Incentive Box</h1>
                    </div>
                    <div className="search-bx">
                        <input
                            name="search"
                            value={searchValue}
                            onChange={handleSearchChange}
                            type="text"
                            placeholder="Search by Dealer Name or GCD Code"
                            maxLength={10}
                        />
                        <button className="search-btn" onClick={() => redirectSearch()}>Search</button>
                        <i className="ic-search"></i>
                    </div>
                    <h3>Incentive Month - {getLastMonth()}</h3> 
                    <div className="dashboard-toggle-tab">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <div className="dashboard-card">
                                        <div className="card color-one">
                                            <div className="roung-bg"> 
                                                <img src={generatedIcon} className="" alt='logo' />
                                            </div>
                                            <span className="count-txt" onClick={() => countClickHandler(1, generatedCount)}>{generatedCount}</span>
                                            <span className="card-txt">Payment Generated</span>
                                        </div>
                                        <div className="card color-two">
                                            <div className="roung-bg">
                                                <img src={paidIcon} className="" alt='logo' />
                                            </div>
                                            <span className="count-txt" onClick={() => countClickHandler(3, paidCount)}>{paidCount}</span>
                                            <span className="card-txt">Payment Paid</span>
                                        </div>
                                        <div className="card color-three">
                                            <div className="roung-bg">
                                                <img src={requestIcon} className="" alt='logo' />
                                            </div>
                                            <span className="count-txt" onClick={() => countClickHandler(2, requestedCount)}>{requestedCount}</span>
                                            <span className="card-txt">Payment Requested</span>
                                        </div>
                                        <div className="card color-four">
                                            <div className="roung-bg">
                                                <img src={onHoldIcon} className="" alt='logo' />
                                            </div>
                                            <span className="count-txt" onClick={() => countClickHandler(4, onHoldCount)}>{onHoldCount}</span>
                                            <span className="card-txt">Payment On Hold</span>
                                        </div> 
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container> 
                    </div> 
                  <div className='row p-lg-t'>
                  <div className='col-md-6'>
                        <IncentiveChart monthList={monthList} quarterList={quarterList}/>
                    </div>
                    <div className='col-md-6'>
                        <TopDealerList topDisbursalList={topDisbursalList}/>
                    </div>
                  </div>
                </div>
            </div>
        </React.Fragment>
    )
}
