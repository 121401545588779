import { gql } from "@apollo/client"

export const Login_User = gql`mutation login_user($email: String!, $password: String!,$hash: String!,$captcha:String!,,$mobile:String!) {
  login_user(email: $email, password: $password,hash: $hash, captcha: $captcha,mobile: $mobile) {
         name 
         id       
         email
         role 
         message
         is_error
 }
}`

export const GOOGLE_EMAIL = (email) => {
  return gql`query {
      login_with_email(email : "${email}") {
        name 
        id       
        email 
        message
        role
        access_token
        report_access  
        is_error
    }
  }`
}
 
export const VALIDATE_OTP = (email, otp, captchaVal) => {
  return gql`query{
    validate_otp(email:"${email}", otp: "${otp}" captcha_val: "") {
      message
      access_token
      report_access  
      }
}`
}


export const GET_REDIS_FLAG = () => {
  return gql`query{
      get_redis_flag{
        isRedis
      }
    }`
}

export const GET_CAPTCHA_IMG = () => {
  return gql`query{
      get_captcha_img{
        message
        image
      }
    }`
}

export const GET_CAPTCHA = () => {
  return gql`query{
    get_captcha
  }`
}