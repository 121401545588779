import React, {useEffect,useState } from 'react';
import Modal from "../../common/Modal";
import { Tab, Nav } from "react-bootstrap";
import CurrencyInputField from "../../common/CurrencyInputField";
import InputField from "../../common/InputField";
import { executeGraphQLQuery} from "../../../../src/common/executeGraphQLQuery"; 
import { GET_INCENTIVE_HISTORY} from "../../../services/incentivedata.gql";  
import { useApolloClient } from '@apollo/client'; 
import FullPageLoader from '../../common/FullPageLoader';

const ConfigHistoryModel = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const [activeType, setActiveType] = useState('');
    const [incentivesAllHistory, setIncentivesAllHistory] = useState({});
    const [loading, setLoading] = useState(false);
    const client = useApolloClient();
    const [incentivesHistory, setIncentivesHistory] = useState([]);
    const [tabList, setTabList] = useState([]);
    const [tabListObj, setTabListObj] = useState({});
    const tabs = ['Monthly Incentives', 'Quarterly Incentives', 'Annual Incentive'];
   
    const handleTypeClick = (action, value) => {  
        if(action === "incentive_type"){
            let incentive=incentivesAllHistory[value];
            if(incentive.length){
              let incentiveHistory=incentive[0]?.items ||[];
              setIncentivesHistory(incentiveHistory);
            }
            setActiveTab(value);
            setTabList(tabListObj[value]);
            setActiveType(1)
        } else {
            if(Object.keys(incentivesAllHistory).length){
                let incentive=incentivesAllHistory[activeTab];
                if(incentive.length){
                  let incentiveHistory=incentive[value-1]?.items ||[];
                  setIncentivesHistory(incentiveHistory);
                }
            }
            setActiveType(value)
        } 
    }
    
    
    
    useEffect(() => { 
        if(!Object.keys(incentivesAllHistory).length){
            getDealerIncentiveHistoryList();
        }
        
    },[incentivesAllHistory,incentivesHistory,tabList,tabListObj]);

    const getDealerIncentiveHistoryList = async() => {
        setLoading(true);
        executeGraphQLQuery(GET_INCENTIVE_HISTORY(), client).then(response => { 
            setLoading(false);
            if(response && response?.data && response.data?.scheme_history) {
                let result = response?.data?.scheme_history;  
                getIncentiveHistoryData(result,activeTab);
            }
        }).catch((error) => {
        
        });
    };

    const getIncentiveHistoryData =(result,activeTab) => {
       
        let monthly_name=(result?.monthly_list.length)?(result?.monthly_list).map((el)=>el.name):[];
        let quaterly_name=(result?.quarterly_list.length)?(result?.quarterly_list).map((el)=>el.name):[];
        let annualy_name=(result?.quarterly_list.length)?(result?.annual_list).map((el)=>el.name):[];
        let monthly_Data=(result?.monthly_list) || [];
        let quaterly_Data=(result?.quarterly_list) || [];
        let annualy_Data=(result?.annual_list) || []

        let tabObj={1:monthly_name,2:quaterly_name,3:annualy_name};
        let incentiveHistoryObj={1:monthly_Data,2:quaterly_Data,3:annualy_Data};
        if(+activeTab===1){
            setActiveType(monthly_name[0]);
        }
        setTabListObj(tabObj);
        setTabList(monthly_name);
        setIncentivesAllHistory(incentiveHistoryObj);
        setIncentivesHistory(monthly_Data[0].items);
    }

    return (
        <div  className="model-popup-outer dealer-financer-popup configHistpopup">
             <FullPageLoader show={loading} />
            <Modal show={props.viewHistory} handleClose={() => props.viewHistoryPopup(false)} >
                <div className="modal-header">
                    <h2> Incentive History </h2>
                </div>
 
                <div className="modal-body">
                    <Tab.Container id="left-tabs-example" defaultActiveKey={'1'} activeTab={activeTab} >
                        {/* MAIN HEADING */}
                        <Nav variant="pills" className="flex-column tab-line">
                            <div className="tab-list">
                                <Nav.Item onClick={() => handleTypeClick("incentive_type", '1')}>
                                    <Nav.Link eventKey="1">Monthly Incentive</Nav.Link>
                                </Nav.Item>
                                <Nav.Item onClick={() =>handleTypeClick("incentive_type", '2')}>
                                    <Nav.Link eventKey="2">Quarterly Incentive </Nav.Link>
                                </Nav.Item>
                                <Nav.Item onClick={() => handleTypeClick("incentive_type", '3')}>
                                    <Nav.Link eventKey="3">Annual Incentive</Nav.Link>
                                </Nav.Item>
                            </div> 
                        </Nav>
                        <Tab.Content key={activeTab} id={activeTab}>
                            <Tab.Pane eventKey={activeTab}>
                                <div className='p-lg-t'>
                                    <Tab.Container id="left-tabs-example" defaultActiveKey={1} activeTab={activeType} >

                                        <Nav variant="pills" className="flex-column tab-bg  incentive-history-month-tab">
                                            <div className="tab-list">
                                                {
                                                    tabList && tabList.length>0 &&
                                                    tabList.map((tab, index) => {

                                                        return (
                                                            // SUB HEADING
                                                            <Nav.Item key={tab} id={tab} onClick={() => handleTypeClick("tablist", index+1)}>
                                                                <Nav.Link eventKey={index+1}>
                                                                   {tab}
                                                                </Nav.Link>
                                                            </Nav.Item> 

                                                        )

                                                    })
                                                }
                                            </div>
                                        </Nav>


                                        <div>
                                            <ul className="financier-group-config incentive-history-month-tab-list">
                                                {incentivesHistory.length > 0 && incentivesHistory.map((inc, index) =>
                                                    <li key={index}>

                                                        <span className="financer-group-label">Disbursed From</span>
                                                        <fieldset className="input m-sm-r">

                                                            <div className="material">
                                                                <InputField
                                                                    inputProps={{
                                                                        id: "disbursal_from_" + index,
                                                                        type: "text",
                                                                        name: "disbursal_from",
                                                                        placeholder: "NA",
                                                                        value: (inc.disbursal_from)?inc.disbursal_from:'NA',
                                                                        className: "form-input",
                                                                        disabled: true,
                                                                        maxLength: 10
                                                                    }}
                                                                />

                                                            </div>

                                                        </fieldset>
                                                        <span className="form-label">Disbursed to</span>
                                                        <fieldset className="input m-sm-r">

                                                            <div className="material">
                                                                <InputField
                                                                    inputProps={{
                                                                        id: "disbursal_to_" + index,
                                                                        type: "text",
                                                                        name: "disbursal_to",
                                                                        placeholder: "NA",
                                                                        value: inc.disbursal_to || 'NA',
                                                                        className: "form-input",
                                                                        disabled: true,
                                                                        maxLength: 10
                                                                    }}
                                                                />

                                                            </div>
                                                        </fieldset>
                                                        <span className="form-label">GMV From</span>
                                                        <fieldset className="input m-sm-r">

                                                            <div className="material">
                                                                <CurrencyInputField
                                                                    inputProps={{
                                                                        id: "gmv_from_" + index,
                                                                        type: "text",
                                                                        name: "gmv_from",
                                                                        placeholder: "NA",
                                                                        value: inc.gmv_from || 'NA',
                                                                        className: "form-input",
                                                                        disabled: true,
                                                                        maxLength: 10
                                                                    }}
                                                                />
                                                            </div>
                                                        </fieldset>
                                                        <span className="form-label">GMV To</span>
                                                        <fieldset className="input m-sm-r">

                                                            <div className="material">
                                                                <CurrencyInputField
                                                                    inputProps={{
                                                                        id: "gmv_to_" + index,
                                                                        type: "text",
                                                                        name: "gmv_to",
                                                                        placeholder: "NA",
                                                                        value: inc.gmv_to || 'NA',
                                                                        className: "form-input",
                                                                        disabled: true,
                                                                        maxLength: 10
                                                                    }}
                                                                />
                                                            </div>
                                                        </fieldset>
                                                        <span className="form-label">Incentive</span>
                                                        <fieldset className="input m-sm-r">

                                                            <div className="material">
                                                                <CurrencyInputField
                                                                    inputProps={{
                                                                        id: "incentive_amount_" + index,
                                                                        type: "text",
                                                                        name: "incentive_amount",
                                                                        placeholder: "NA",
                                                                        value: inc.incentive_amount || 'NA',
                                                                        className: "form-input",
                                                                        disabled: true,
                                                                        maxLength: 10
                                                                    }}
                                                                />
                                                            </div>
                                                        </fieldset>
                                                    </li>)

                                                }
                                            </ul>
                                        </div>
                                    </Tab.Container>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
                {/* </div> */}
            </Modal>
        </div>
    )
};

export default ConfigHistoryModel;
