import { gql } from '@apollo/client';

export const GET_DEALER_INCENTIVE_DATA = () => {
    return gql`query incentive_list($filter_list: FilterInput!) { 
        incentive_list(filter_list:$filter_list) { 
            message,
            is_error, 
            userinfo
                {
                    user_id,
                    user_name,
                    gcd_code,
                    bank_name,
                    beneficial_name,
                    account_number,
                    items
                    {
                        total_disbursal
                        total_gmv
                        amount
                        deduction
                        salary_arrear
                        final_amount
                        incentive_month 
                        status
                        status_name
                        bank_name
                        beneficial_name
                        account_number
                        scheme_type_name
                        scheme_type_id
                        id
                        modify_status
                        modify_status_name
                    }
            }
            pagination
            {
                pages
                total
                limit
                cur_page
                next_page
                prev_page
            }
        }
    }`
}



export const GET_DEALER_HISTORY =(id,month)=> {
    return gql`query{
        user_history(user_id:${id}){
            message,
            is_error,
            data{
            scheme_type_name 
            total_disbursal
            total_gmv
            amount
            deduction
            salary_arrear
            final_amount
            incentive_month
            status
            status_name
            bank_name
            beneficial_name
            account_number
            }
        }
    }`
}


export const EXPORT_DEALER_INCENTIVE_DATA = () => {
    return gql`query incentive_list($filter_list: FilterInput!) { 
        incentive_list(filter_list:$filter_list) {  
            userinfo
                {
                    user_id,
                    user_name,
                    gcd_code,
                    bank_name,
                    beneficial_name,
                    account_number,
                    items
                    {
                        total_disbursal
                        total_gmv
                        amount
                        deduction
                        salary_arrear
                        final_amount
                        incentive_month
                        status
                        status_name
                        bank_name
                        beneficial_name
                        account_number
                        scheme_type_name
                        scheme_type_id
                        id
                        modify_status
                        modify_status_name
                    }
            }
        }
    }`
}

export const UPDATE_DIFF_AMOUNT = gql`mutation incentive_amount_update($incentiveInput: IncentiveInput!) {
     incentive_amount_update(incentive_input: $incentiveInput) {
            message,
            is_error,
            data{
                id
                deduction
                salary_arrear
                final_amount
            }
    }
}`;


export const UPDATE_STATUS = gql`mutation approve_amount($approve_input: ApproveInput!) {
    approve_amount(approve_input: $approve_input) {
           message
           is_error
   }
}`;

export const GET_PAID_URL =(id)=> {
    return gql`query{
        download_url(id:${id}){
            message,
            is_error,
            data
        }
    }`
}





