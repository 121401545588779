import { gql } from '@apollo/client';

export const GET_MASTER_DATA = () => gql`
    query{
      master_config(status:1) {
        scheme_type_list{
            id
            name
        } 
        status_list{
            id
            name 
        } 
        modify_status_list{
            id
            name 
        } 
        sample_csv {
            type
            url
        }
    }
    }
`;
