import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import PdfUploader from "../dashboard/common/PdfUploader";
import { Tab, Nav } from "react-bootstrap";
import React, { act, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {useDispatch } from "react-redux";
import { useApolloClient } from '@apollo/client';
// import MonthViewTable from "./MonthViewTable";

// import DealerViewTable from "./DealerViewTable";

import ViewTable from "./ViewTable";
import * as HELPER from '../../config/helper';
import DealerListFilter from "./DealerListFilter";
import FullPageLoader from "../common/FullPageLoader";
import ConfirmationModal from "../common/ConfirmationModal";
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { EXPORT_DEALER_INCENTIVE_DATA, GET_DEALER_INCENTIVE_DATA,UPDATE_STATUS } from "../../services/dealerdata.gql";
import { getDealerData } from "../../store/action/allAction";
import { executeGraphQLMutation } from "../../common/executeGraphQLQuery";
import DealerListTable from "./DealerListTable";
import dealersIcon from "../../webroot/images/dealers.svg";
import monthViewIcon from "../../webroot/images/month_view.svg";



const DealerList = (props) => {
    const [selectedDealerIdRecord,setSelectedDealerRecord] = useState({})
    const [activeTab, setActiveTab] = useState(1);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [data, setData] = useState(null);
    const [csvData, setCSVData] = useState([]);
    const [btnEnable, setBtnEnable] = useState(false);
    const [isDownloadReady, setIsDownloadReady] = useState(false);
    const [showApproveReject, setShowApproveReject] = useState(false);
    const [confimationPopUp, setConfirmationPopup] = useState({ status: false, msg: '' });
    const [selectedDealerIds, setSelectedDealerIds] = useState()
    const [removeSelectAll, setRemoveSelectAll] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const client = useApolloClient();
    const { t } = useTranslation("common");
    const dispatch = useDispatch()
    const USER_DATA = JSON.parse(localStorage.getItem("user_information")); 

    const exportHeaders =(activeTab===1)?[
        { label: t('DEALER.DEALERSHIP_NAME'), key: "name" },
        { label: t('DEALER.TYPE'), key: "type" },
        { label: t('DEALER.DISBURSAL_GMV'), key: "disbursal_gmv" },
        { label: t('FORM.INCENTIVE'), key: "incentive" },
        { label: t('DEALER.BANK_DETAILS'), key: "bank_details" },
        { label: t('DEALER.DEDUCTION'), key: "deduction" },
        { label: t('DEALER.SALARY_ARREAR'), key: "salary_arrear" },
        { label: t('DEALER.FINAL_INCENTIVE'), key: "final_incentive" },
        { label: t('DEALER.STATUS'), key: "status" },
    ]:[  { label: t('DEALER.DEALERSHIP_NAME'), key: "name" },
    { label: t('DEALER.TYPE'), key: "type" },
    { label: t('DEALER.INCENTIVE_MONTH'), key: "incentive_month" },
    { label: t('DEALER.DISBURSAL_GMV'), key: "disbursal_gmv" },
    { label: t('FORM.INCENTIVE'), key: "incentive" },
    { label: t('DEALER.BANK_DETAILS'), key: "bank_details" },
    { label: t('DEALER.DEDUCTION'), key: "deduction" },
    { label: t('DEALER.SALARY_ARREAR'), key: "salary_arrear" },
    { label: t('DEALER.FINAL_INCENTIVE'), key: "final_incentive" },
    { label: t('DEALER.STATUS'), key: "status" },];

    const changeActiveTab =(activetab) => {
        setActiveTab(activetab);
        setShowApproveReject(false);
        setFilters({})
    }

    /**
     * function to call api for fetching data
     * @param {*} params filters for api
     * @param {*} type defines for what purpose data is being fetched
     * @returns result of api call
     */
    const getDataListing = async (params, type) => {
        setLoading(true);
        setData([]);
        let updatedFilters = {}
        //when reseting
        if (type !== 'reset') {
            updatedFilters['filter_list'] = { ...filters }
        }else{
            setShowApproveReject(false);
            setRemoveSelectAll(true);
        }

        updatedFilters['filter_list'] = { ...updatedFilters?.['filter_list'], ...params }
           
        const result = await dispatch(getDealerData(client, updatedFilters));
        setLoading(false);
        setData([])
        if(result?.data?.incentive_list?.userinfo?.length &&  !result?.data?.incentive_list?.is_error) {
            setBtnEnable(true)
            setData(result) //enable disable export btn
        } else{
            toast.error(result?.data?.incentive_list.message);
            setBtnEnable(false)
            setData([])
        } 
        setFilters(updatedFilters.filter_list)
        return result
    }

    /**
     * function to export CSV
     * @returns 
     */
    const handleExport = async () => {
        setLoading(true)
        let updatedFilters = { filter_list: { ...filters, view_type:activeTab } }
        delete updatedFilters?.['filter_list']?.page_number //delete pagination for export

        const result = await executeGraphQLQuery(EXPORT_DEALER_INCENTIVE_DATA(), client, updatedFilters);
        let newCSVData=[];
        if(result && result?.data && result?.data?.incentive_list &&  result?.data?.incentive_list?.userinfo){
            let userinfo=result?.data?.incentive_list?.userinfo;
            for(let ele of userinfo){
                let entries = ele.items || [];
                for (const [idx, item] of entries.entries()) {
                    let itemStatus=true;
                    if(idx!== 0 && entries[idx-1]["scheme_type_id"]===item.scheme_type_id && item.modify_status===3){
                        itemStatus=false;
                    }else if(idx!== 0 && entries[idx-1]["scheme_type_id"]===item.scheme_type_id && entries[idx-1]["modify_status"]===3 && item.modify_status!==3){
                        itemStatus=false;
                    }
                    if(itemStatus){
                        
                        let status= item.status_name;
                        if(idx!== 0){
                            let filterData=entries.filter((el)=>el.scheme_type_id===item.scheme_type_id && el.incentive_month===item.incentive_month);
                            if(filterData.length && filterData[0].id===item.id){
                                status=item.status_name;
                            }else{
                                status='';
                            }
                        }
                        let bankDetails=``;
                        if(item.bank_name){
                            bankDetails+=([1,4].includes(item.status))?ele.bank_name:item.bank_name;
                        }
                        if(item.account_number){
                            bankDetails+=([1,4].includes(item.status))?" "+ele.account_number:" "+item.account_number;
                        }
                        if(item.beneficial_name){
                            bankDetails+=([1,4].includes(item.status))?" "+ele.beneficial_name:" "+item.beneficial_name;
                        }
                        let data= {
                            name: ele.user_name + " GCD " + ele.gcd_code,
                            type: item.scheme_type_name,
                            disbursal_gmv: item.total_disbursal + " Disbursal GMV " + HELPER.addCurrencyFormatting(item.total_gmv),
                            incentive: HELPER.addCurrencyFormatting(item.amount),
                            bank_details: bankDetails || "N/A",
                            deduction:(item.deduction)?HELPER.addCurrencyFormatting(item.deduction):'',
                            salary_arrear:(item.salary_arrear)?HELPER.addCurrencyFormatting(item.salary_arrear):'',
                            final_incentive: HELPER.addCurrencyFormatting(item.final_amount),
                            status:status     
                        }
                        if(activeTab===2){
                            data["incentive_month"]=`${item.incentive_month}`
                        }
                        newCSVData.push(data);
                    }
                 
                }
            }
        }
       
        setLoading(false)

        if (newCSVData?.length) {
            setCSVData(newCSVData)
            setIsDownloadReady(true)
        } else {
            toast.error(t("DEALER.NO_DATA_FOUND"))
            setCSVData([])
            setIsDownloadReady(false)
        }
        return
    }

    const showModalConfimationPopUp = (params) => {
        let { status } = params 
        setConfirmationPopup(params)
        document.body.classList.remove("overflow-hidden");
        if (status) {
            document.body.classList.add("overflow-hidden");
        }
    }

    const showApproveRejectButton = (status) => {
        setShowApproveReject(status);
    }

    const confirmationModalResponse = (params) => {
        if(params.response){ 
            setLoading(true); 
            let approve_input= {
                "approve_input": { 
                    "month": selectedDealerIdRecord?.incentive_month,  
                    "parent_id": selectedDealerIdRecord?.dealer_ids?.join(","),
                    "status": confimationPopUp.status_id,
                    "created_by": USER_DATA?.id,
                    "select_all":selectAll,
                    "filters":filters

                }
            } 
            executeGraphQLMutation(UPDATE_STATUS,approve_input, client).then(response => { 
                setLoading(false);  
                if(response && response.data && response?.data?.approve_amount && !response?.data?.approve_amount?.is_error) { 
                    toast.success(response.data.approve_amount.message);
                }else{
                    toast.error(response.data.approve_amount.message)
                }
                getDataListing(filters);
            }).catch((error) => { setLoading(false); });   
        }
        setShowApproveReject(false);
        setRemoveSelectAll(true);
        showModalConfimationPopUp(params);
    }
    const parentFilter=(params)=>{
        let filterData={...params,...filters}
        setFilters(filterData);
        getDataListing(filterData);
    }

    /**
     * useEffect specifically to download csv
     */
    useEffect(() => {
        if (isDownloadReady) {
            document?.getElementById("csvDownloadLink")?.click();
            setIsDownloadReady(false)
        }
    }, [isDownloadReady]) //as soon as our csv data gets ready, export data by click

    // useEffect(() => {
    //     let updatedSelctedFilters = {};
    //     if (search) {
    //         updatedSelctedFilters['search'] = search;
    //     }
    //     if (status) {
    //         updatedSelctedFilters['status'] = [status];
    //     }
    //     setFilters({ ...updatedSelctedFilters })
       
    // }, [search,status])

    return (
        <React.Fragment>
            <FullPageLoader show={loading} />

            <div className="dealer-outer">
                <div className="container-fluid">
                    <div className="top-heading bg-white dealer-incentive-top-heading minus-l-r-15">
                        <h1>Dealer Incentive</h1>
                    </div>

                    <div className="dealer-incentive-outer">
                        <Tab.Container id="left-tabs-example" defaultActiveKey={'1'} activeTab={activeTab} >
                            {/* MAIN HEADING */}
                            <Nav variant="pills" className="flex-column tab-line bg-white minus-l-r-15">
                                <div className="tab-list">
                                    <Nav.Item onClick={() => {changeActiveTab(1)}}>
                                        
                                        <Nav.Link eventKey="1">  
                                      
                                               <img src={monthViewIcon} className="iconView" alt='logo' /> Month View
                                            
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => {changeActiveTab(2)}}>
                                        <Nav.Link eventKey="2">  
                                           <img src={dealersIcon} className="" alt='logo' /> Dealer View 
                                      
                                       
                                        </Nav.Link>
                                    </Nav.Item> 
                                </div>
                                <div className="upload-eport-btn d-flex">
                                {
                                  showApproveReject ?
                                    <>
                                        <button className="btn-primary m-sm-r" onClick={() => showModalConfimationPopUp({ status: true, status_id: 2, msg: t("DEALER.APPROVE_MESSAGE")})}> <i className="ic-check"></i>Approve</button>
                                        <button className="btn-line m-sm-r reject-btn" onClick={() => showModalConfimationPopUp({ status: true, status_id: 5, msg: t("DEALER.REJECT_MESSAGE")})}> <i className="ic-clearclose"></i>Reject</button>
                                    </>:""  
                                }
                                <PdfUploader getDataListing={getDataListing} filters={filters}/>
                                    <button className="btn-line  m-sm-l" disabled={!btnEnable} onClick={() => {
                                        handleExport()
                                    }}>
                                        <i className="ic-export"></i>
                                        Export Data
                                    </button>
                                    {isDownloadReady && (
                                        <CSVLink
                                            data={csvData}
                                            headers={exportHeaders}
                                            filename={"data.csv"}
                                            id="csvDownloadLink"
                                            style={{ display: "none" }} // Hide the actual link
                                        />
                                    )}
                                </div>

                            </Nav>

                            <Tab.Content key={activeTab} id={activeTab}>
                                <Tab.Pane eventKey={activeTab}>
                                    <div className="loan-filter-bg dashboard-filter-top m-lg-t m-lg-b">
                                        <DealerListFilter fetchListingData={getDataListing} setParentFilter={parentFilter} activeTab={activeTab}  />
                                    </div>
                                    <div className='payout-summary-tabs'>
                                        <Tab.Container id="left-tabs-example">
                                            <ViewTable setLoader={setLoading} fetchListingData={getDataListing} data={data} showApproveRejectButton={showApproveRejectButton} setSelectedDealerRecord={setSelectedDealerRecord} selectedDealerIdRecord={selectedDealerIdRecord} pageType={activeTab} removeSelectAll={removeSelectAll} setRemoveSelectAll={setRemoveSelectAll} setSelectAll={setSelectAll} />
                                            {/* <DealerListTable setLoader={setLoading} fetchListingData={getDataListing} data={data} showApproveRejectButton={showApproveRejectButton} setSelectedDealerRecord={setSelectedDealerRecord} selectedDealerIdRecord={selectedDealerIdRecord} pageType={activeTab} /> */}
                                        </Tab.Container>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
            <ConfirmationModal confirmationData={{ show: confimationPopUp.status, confirmationText: confimationPopUp.msg }} confirmationModalResponse={confirmationModalResponse} />
        </React.Fragment>
    );
};

export default DealerList;
